import React from 'react';
import { Link } from 'gatsby';

const PostCard = ({ slug, title, excerpt, date, timeToRead }) => (
  <div className="flex justify-center items-center my-3 p-3 lg:w-1/2 rounded-md shadow-md bg-white border">
    <div>
      <Link to={slug} className="text-2xl font-bold">
        <h3>{title}</h3>
      </Link>
      <h5 className="text-gray-500">
        {date} - {timeToRead} min read
    </h5>
      <span className="">{excerpt}</span>
    </div>
  </div>
);

export default PostCard;
