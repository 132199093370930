import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import SEO from './../components/SEO';
import PostCard from '../components/PostCard';

const Blog = ({ location, data }) => {
  const { edges: posts } = data.allMdx;
  return (
    <Layout>
      <SEO location={location.pathname} title="📝 My writings" />
      <div>
        <div className="flex justify-center items-center flex-col">
          {posts.map(({ node }) => (
            <PostCard
              key={node.id}
              title={node.frontmatter.title}
              slug={node.fields.slug}
              excerpt={node.excerpt}
              date={node.frontmatter.date}
              timeToRead={node.timeToRead}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
query blogIndex {
  allMdx(
    filter: {frontmatter: {isPublished: {ne: false}, type: {eq: "post"}}},
    sort: {order: DESC, fields: [frontmatter___date]}) {
    edges {
      node {
        id
        excerpt
        timeToRead
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
        }
        fields {
          slug
        }
      }
    }
  }
}

`;

export default Blog;
